#aussi{
        background-color: rgb(14, 28, 41);
        color: white;
        padding: 1rem;
        text-align: center;
        font-size: 1.3rem;
}
#aussi p{
        width: 80%;
        margin: 2rem auto;
}
#aussi span{
        text-decoration: yellow underline;
}
#partenaires{
        display: flex;
        width: 80%;
        margin: auto;
}
#partenaires :first-child{
        background-color: rgb(236, 236, 236);
}
#partenaires div{
        flex: 1;
}
#partenaires img{
        width: 100%;
        height: 100%;
}
#partenaires ul{
        display: block;
        height: auto;
}
#partenaires ul li{
        font-weight: normal !important;
        text-align: left;
        margin-left: 3rem;
}
#partenaires p{
        margin: 2rem;
}
#partenaires button{
        display: block;
        margin: auto;
        position: relative;
        background-color: rgb(14, 28, 41);
        color: white;
        font-family:  sans-serif;
        padding: 0.9rem 0.5rem;
        border-radius: 4px;
        font-weight: bolder;
        border: 0;
}
#inox{
        margin-top: 6rem;
        margin-left: 10%;
        width: 40%;
        background-color: rgb(14, 28, 41);
        color: white;
        box-shadow: 20px 20px rgb(32, 32, 32);

}
#inox ul{
        list-style: white square;
        list-style-type: square;
        display: block ;
        list-style: white square;
        list-style-type: square;
        font-weight: normal;
}
       
#inox ul li{
        list-style: white square;
        list-style-type: square;
        text-align: left;
        flex: none;
        margin: 0.5rem;
        margin-left: 2rem;
  
}
#inox p{
        margin: 1rem;
        text-align: center;
        font-weight: bold;
}
#acier{
        margin-left: 50%;
        width: 40%;
        background-color: rgb(236, 236, 236);
        box-shadow: 20px 20px rgb(32, 32, 32);
}
#acier p{
        margin: 1rem;
        text-align: center;
        font-weight: bold;
}
#acier ul{
        display: block;
        font-weight: normal;
        list-style: square;
       
}
#acier ul li{
        text-align: left;
        margin: 0.5rem;
        margin-left: 2rem;
}
#escaliers{
        width: 80%;
        margin: auto;
        background-color: rgb(14, 28, 41) ;
        color: white;
        display: flex;
}
#escaliers h3{
        background-color: rgb(14, 28, 41) !important ;
        color: white !important;
}
#escaliers2 div{
        padding: 1rem;
}
#escaliers div :first-child{
        background-color: rgb(236, 236, 236);
        color: rgb(14, 28, 41) ;
}
#escaliers img{
        width: 100%;
        height: 100%;
}
#mezza{
        display: flex;
        width: 80%;
        margin: auto;
}
#mezza div{
        flex: 1;
        background-color: rgb(236, 236, 236);
}
#mezza p{
        margin: 2rem;
}
#mezza img{
        width: 100%;
        height: 100%;
}
button a{
        text-decoration: none;
        color: white;
        background-color: rgb(14, 28, 41) !important ;
}