#magasins{
        background-color: rgb(14, 28, 41);
        color: white;
        padding: 1rem;
        text-align: center;
        font-size: 1.3rem;
}
#magasins p{
        width: 80%;
        margin: 2rem auto;
}
#magasins span{
        text-decoration: yellow underline;
}
#travaillons{
        display: flex;
        width: 80%;
        margin: auto;
        background-color: rgb(236, 236, 236);
}
#travaillons div{
        flex: 1;
}
#travaillons p{
        margin: 2rem;
}
#travaillons img{
        width: 100%;
        height:100%;
}
#allu{
        margin-top: 6rem;
        margin-left: 10%;
        width: 40%;
        background-color: rgb(236, 236, 236);
        box-shadow: 20px 20px rgb(32, 32, 32);
}
#allu p{
        margin: 2rem;
}
#allu h3{
        text-align: center;
}
#bois{
        margin-left: 50%;
        width: 40%;
        background-color: rgb(14, 28, 41);
        color: white;
        box-shadow: 20px 20px rgb(32, 32, 32);
}
#bois p{
        margin: 2rem;
}
#bois h3{
        text-align: center;
}
#windows{
        display: flex;
        width: 80%;
        margin: auto;
        background-color: rgb(14, 28, 41);
      
}
#windows :first-child{
        background-color: rgb(236, 236, 236);
        color: rgb(14, 28, 41);
}
#windows p{
        margin: 2rem;
}
#windows ul{
        list-style: square;
        margin: 2rem;
        font-weight: normal;
        background-color: rgb(14, 28, 41) !important;
        height: auto;
        color: white !important;
        margin-top: -1rem;
}
#windows ul li{
        margin: 1rem;
        background-color: rgb(14, 28, 41) !important;
}
#windows div{
        flex: 1;
}
#baie{
        display: flex;
        width: 80%;
        margin: auto;
        margin-top: 6rem;
        background-color: rgb(14, 28, 41);
        color: white;
}
#baie div{
        flex: 1;
}
#baie img{
        width: 100%;
        height: 300px;
}
#baie h3{
        text-align: center;
        margin: 2rem;
}
#baie p{
        margin: 2rem;
}
#miroir{
        display: flex;
        width: 80%;
        margin: auto;
        background-color: rgb(14, 28, 41);
        color: white;
}
#miroir div{
        flex:1;
}
#miroir img{
        width: 100%;
        height: 100%;
}
#miroir p{
        margin: 2rem;
}
#miroir ul{
        height: auto;
        text-align: left;
        font-weight: normal;
        margin: 2rem 4rem;
}
#miroir ul li{
        list-style: square;
}
#volets{
        display: flex;
        width: 80%;
        margin: auto;
        background-color: rgb(236, 236, 236);
}
#volets div{
        flex: 1;
}
#volets img{
        width: 100%;
        height: 100%;
}
#volets p{
        margin: 2rem;
}
#rido{
        background-color: rgb(14, 28, 41);
        color: white;
        width: 80%;
        margin: auto;
}
#rido p{
        margin: 2rem;
}
#rido ul{
        text-align: left;
        font-weight: normal;
        margin: 2rem 4rem;
}
#rido ul li{
        text-align: left;
        list-style: square;
}
#rido span{
        text-decoration: yellow underline;
}