#notre{
        background-color: rgb(14, 28, 41);
        color: white;
        padding: 1rem;
        text-align: center;
        font-size: 1.3rem;
}
#notre p{
        width: 80%;
        margin: 2rem auto;
}
#blinde{
        display: flex;
        width: 80%;
        margin: auto;
}
#blinde div{
        flex: 1;
        background-color: rgb(236, 236, 236);
}
#blinde img{
        width: 100%;
        height: 100%;
}
#blinde p{
        margin: 2rem;
}
#serrure{
        margin-top: 6rem;
        margin-left: 10%;
        width: 40%;
        background-color: rgb(14, 28, 41);
        color: white;
        box-shadow: 20px 20px rgb(32, 32, 32);
}
#serrure h3{
        text-align: center;
}
#serrure p{
        margin: 2rem;
}
#modele{
        margin-top: 0;
        margin-left: 50%;
        width: 40%;
        background-color: rgb(236, 236, 236);
        box-shadow: 20px 20px rgb(32, 32, 32);
}
#modele h3{
        text-align: center;
}
#modele p{
        margin: 2rem;
}
#modele ul{
        margin: 0 4rem;
        list-style: square;
}
#modele ul li{
        text-align: center;
        font-weight: normal;
}
#urgence{
        display: flex;
        width: 80%;
        margin: auto;
}
#urgence :last-child{
        background-color: rgb(14, 28, 41);
}
#urgence div{
        flex: 1;
        background-color: rgb(236, 236, 236);
}
#urgence img{
        width: 100%;
        height: 100%;
}
#urgence p{
        background-color: rgb(236, 236, 236) !important;
        margin: 2rem;
}