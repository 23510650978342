#siege{
        background-color: rgb(14, 28, 41);
        color: white;
        padding: 1rem;
        text-align: center;
        font-size: 1.3rem;
}
#siege p{
        width: 80%;
        margin: 2rem auto;
}
#siege ul{
        width: 50%;
        margin: auto;
}
#siege ul li{
        font-weight: normal;
        text-align: justify;
        list-style: square;
        font-size: 1.1rem;
}
#siege a{
        color: white;
        text-decoration: yellow underline;
}
#admin{
        display: flex;
        width: 80%;
        margin: auto;
        background-color: rgb(236, 236, 236);
        height: 240px;
}
#admin div{
        flex: 1;
}
#admin img{
        width: 100%;
        height: 100%;
}
#admin ul{
        margin: 2rem;
        list-style: square;
}
#admin ul li{
        font-weight: normal;
        text-align: left;
        margin: 1rem;
}
#commerces{
        display: flex;
        width: 80%;
        margin: auto;
        background-color: rgb(14, 28, 41);
        color: white;
}
#commerces div{
        flex: 1;
}
#commerces img{
        width: 100%;
        height: 100%;
}
#commerces ul li a{
        text-decoration: yellow underline;
        color: white;
        font-size: 1.5rem;
}
#commerces ul li a:hover{
        font-weight: bold;
}
#commerces ul{
        margin: 2rem;
        margin-left: 6rem;
        list-style: square;
}
#commerces ul li{
        font-weight: normal;
        text-align: left;
        margin: 0.2rem;
}
#assurances1{
        background-color: rgb(236, 236, 236);
        margin-left: 10%;
        width: 40%;
        margin-top: 6rem;
        box-shadow: 20px 20px rgb(32, 32, 32);
}
#assurances1 h3{
        text-align: center;
        font-size: 1.5rem;
}
#assurances1 ul{
        text-align: center;
        font-weight: normal;
        list-style: none;
}
#assurances1 a{
        color: rgb(14, 28, 41);
}
#assurances1 a:hover{
        font-weight: bold;
}
#assurances1 ul li{
        margin: 0.1rem;
}
#assurances2{
        margin-left: 50%;
        width: 40%;
        background-color: rgb(14, 28, 41);
        color: white;
        box-shadow: 20px 20px rgb(32, 32, 32);
}
#assurances2 h3{
        text-align: center;
        font-size: 1.5rem;
}
#assurances2 ul{
        text-align: center;
        font-weight: normal;
        list-style: none;
}
#assurances2 ul li{
        margin: 0.1rem;
}
#assurances2 a{
        color: white;
        text-decoration: yellow underline;
}
#assurances2 a:hover{
        font-weight: bold;
}