body{
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-style: normal;
        color: rgb(14, 28, 41);
}
#arrow{
        position: fixed;
        z-index: 1;
        width: 100px;
        bottom: 2rem;
        right: 2rem;
}
#arrow:hover{
        animation: animArrow 0.5s infinite linear;
}
@keyframes animArrow{
        0%{transform: scale(1);}
        100%{transform: scale(1.2);}
}
#padHeader{
        display: none;
}
@keyframes animPadHeader{
        0%{transform: scaleX(0);}
        100%{transform: scaleX(1);}
}
#banniere{
        display: flex;
}
#ban1{
        flex: 1;
}
#ban2{
        flex: 5;
        background-color: rgb(14, 28, 41);
        color: white;
        font-weight: bold;
        text-align: left;
        border-left: 1px groove rgb(14, 28, 41);
}
#padLegal{
        display: none;
}
#padNavig{
      display: none;
      animation: animPadHeader 0.5s 1 linear;
      transform-origin: 0;
      background-color: rgb(236, 236, 236); padding: 0.5rem;
}
#padNavig ul{
        list-style: none;
       
}
#ban2 h2{
        margin: 0;
        padding: 0.5em 0;
        font-size: 1.1rem;
        text-decoration: none!important;
        text-transform: uppercase;
        letter-spacing: 0.3rem;
      
}
#menu{
        display: flex;
        height: 150px;
}
#logo{
        flex: 1;
        text-align: center;
}
#logo img{
        height: 60%;
        position: relative;
        top: -15px;
       
}
#navig{
        flex: 5;
        height: auto;
}
nav{
        background-color: rgba(255, 255, 255, 0.9);
        height: 110px;
}
ul{
        margin: 0;
        padding: 0;
        font-weight: bold;
        height: 100%;
}
li{
        flex: 1;
        text-align: center;
}
nav ul{
        list-style: none;
        display: flex;
}
nav ul li a{
        text-decoration: none;
        font-size: 1.1rem;
        position: relative;
        top: 35%;
        color: rgb(14, 28, 41);
        text-transform: uppercase;
}
nav ul li a:hover{
        text-decoration: underline;
}
nav ul span{
        margin: 0;
        position: relative;
        top: 35%;
}
header{
        height: 700px;
        background-color: rgb(236, 236, 236);
        background-image: url('./images/eyeemfiltered1579424914184.jpg');
        background-size: cover;
        background-position: 0;
}
#presentation{
        position: relative;
        top: 70px;
        width: 40%;
        margin: auto;
}
#presentation div{
        position: relative;
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.9);
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        animation: slide 0.5s 1 linear;
        animation-fill-mode: forwards;
}
@keyframes slide{
        0%{transform: scale(0);opacity: 0;}
        100%{ transform: scale(1);opacity: 1;}
}
#Metal{
        background-color: rgba(255, 255, 255, 0.9);
        width: 50%;
        margin: auto;
        color: rgb(14, 28, 41);
        display: none;
        padding: 2rem;
        position: relative;
        top: 70px;
        animation: metal 0.5s 1 linear;
        animation-fill-mode: forwards;
}
#Metal h2{
    margin: 0;
    text-decoration: none;
}
@keyframes metal{
        0%{opacity: 0; left: 20%;}
        100%{opacity: 1; left: 0%;}
}
#Facades{
        background-color: rgba(255, 255, 255, 0.9);
        width: 50%;
        margin: auto;
        color: rgb(14, 28, 41);
        display: none;
        padding: 2rem;
        position: relative;
        top: 70px;
        animation: facades 0.5s 1 linear;
        animation-fill-mode: forwards;
}
#Facades h2{
        margin: 0;
        text-decoration: none;
}
@keyframes facades{
        0%{opacity: 0; left: -20%;}
        100%{opacity: 1; left: 0%;}
}
#Batiments{
        background-color: rgba(255, 255, 255, 0.9);
        width: 50%;
        margin: auto;
        color: rgb(14, 28, 41);
        display: none;
        padding: 2rem;
        position: relative;
        top: 70px;
        animation: metal 0.5s 1 linear;
        animation-fill-mode: forwards;
}
#Batiments h2{
        margin: 0;
        text-decoration: none;
}
#References{
        background-color: rgba(255, 255, 255, 0.9);
        width: 50%;
        margin: auto;
        color: rgb(14, 28, 41);
        display: none;
        padding: 2rem;
        position: relative;
        top: 70px;
        animation: facades 0.5s 1 linear;
        animation-fill-mode: forwards;
}
#References h2{
        margin: 0;
        text-decoration: none;
}
#References img{
        width: 150px;
}
#Contact{
        position: relative;
        top: 70px;
        width: 40%;
        margin: auto;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.9);
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        animation: slide 0.5s 1 linear;
        animation-fill-mode: forwards;
        display: none;
}
#Contact p{
        font-size: 1.2rem;
        text-transform: uppercase;
}
#Legalo{
        background-color: rgba(255, 255, 255, 0.9);
        width: 50%;
        margin: auto;
        color: rgb(14, 28, 41);
        display: none;
        padding: 2rem;
        position: relative;
        top: 70px;
        animation: slide 0.5s 1 linear;
        animation-fill-mode: forwards;
}
#Legalo h2{
        margin: 0;
        text-decoration: none;
}
#Contact ul{
        margin: 1rem;
        list-style: none;
        font-weight: normal;
}
h1::selection{
        background-color: black;
}
h2::selection{
        background-color: black;
        color: white;
}
a::selection{
        background-color: black;
        color: white;
}
h3::selection{
        background-color: black;
        color: white;
}
p::selection{
        background-color: black;
        color: white;
}
li::selection{
        background-color: black;
        color: white;
}
b::selection{
        background-color: black;
        color: white;
}
span::selection{
        background-color: black;
        color: white;
}
img::selection{
        background-color: white;
}
label::selection{
        background-color: black;
        color: white;
}
input::selection{
        background-color: black;
        color: white;
}
button::selection{
        background-color: black;
        color: white;
}
br::selection{
        background-color: black;
        color: white;
}