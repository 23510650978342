#nousContacter{
        background-color: rgb(14, 28, 41);
        color: white;
        padding: 1rem;
        text-align: center;
        font-size: 2rem;
        display: flex;
        padding-bottom: 6rem;
}
#nousContacter ul{
        list-style: none;
        font-size: 1.5rem;
}
#nousContacter div{
        flex: 1;
}
#formulaire{
        background-color: rgb(236, 236, 236);
        width: 80%;
        margin: auto;
        display: flex;
}
#formulaire div{
        flex: 1;
}
#formulaire p{
        margin: 2rem;
}
#formulaire :first-child{
        background-color: rgb(14, 28, 41);
        color: white;
}
#formulaire img{
        display: block;
        margin: auto;
        width: 20%;
}
label{
        font-style: italic;
        display: block;
        width: 100%;
        background-color: rgb(236, 236, 236) !important;
        color: rgb(14, 28, 41) !important;
}
input{
        display: block;
        width: 100%;
        padding: 0.5rem;   
        margin-bottom: 0.2rem;     
        box-shadow: 2px 2px rgb(14, 28, 41);
        border: 0;
}
textarea{
        display: block;
        width: 100%;
        padding: 0.5rem;
          box-shadow: 2px 2px rgb(14, 28, 41);
        border: 0;
}
form{
        width: 85%;
        margin: auto;
        margin: 2rem;
        background-color: rgb(236, 236, 236) !important;
}
#boutons{
        background-color: rgb(236, 236, 236) !important;
        text-align: center;
}
#boutons button{
        background-color: rgb(14, 28, 41);
        color: white;
        font-family:  sans-serif;
        padding: 0.7rem 0.5rem;
        border-radius: 4px;
        font-weight: bolder;
        border: 0;
        width: 100px;
}
#data input{
        display: inline !important;
        width: auto; 
}
#dataSpan{
        color: rgb(14, 28, 41) !important;
}
form div{
        margin: 0.5rem;
}