h1{
        margin: 0;
        padding: 3rem;
        text-align: center;
        font-size: 4rem;
        background-color: rgb(14, 28, 41);
        color: white;
        letter-spacing: 0.5rem;
}
h2{
        text-align: center;
        font-size: 2.5rem;
        text-decoration:  yellow underline;
        text-transform: uppercase;
        margin: 5rem 0;
        letter-spacing: 0.1rem;
}
#exp{
        background-color: rgb(236, 236, 236);
        width: 80%;
        margin-bottom: 0;
        margin: auto;
        display: flex;
}
#exp div{
        flex: 1;

        text-align: center;
}
#exp p{
        margin: 2rem;
}
#exp div img{
     height: 100%;
     width: 100%;
     margin: 0;
}
#plusengage{
        margin-top: 0;
        width: 80%;
        margin: auto;
        background-color: rgb(14, 28, 41);
        color: white;
}
#plusengage article ul{
        display: block;
        list-style: none;
        font-weight: normal;
}
#plusengage article ul li{
        border: 0;
}
#plusengage article{
        margin: 0;
}
#plusengage h3{
        text-decoration: yellow underline;
        font-size: 1.5rem;
        margin: 0rem;
        text-align: center;
}
#mesure{
        width: 50%;
        margin: auto;
        text-align: center;
        font-weight: bold;
        margin-bottom: 6rem;
        font-size: 1.2rem;
}
#fournisseurs{
        position: relative;
        background-color: rgb(236, 236, 236);
        margin-left: 10%;
        width: 40%;
        box-shadow: 20px 20px rgb(32, 32, 32);
}
#fournisseurs p{
        margin: 2rem;
}
#produits{
        position: relative;
        width: 40%;
        background-color: rgb(14, 28, 41);
        color: white;
        margin-left: 50%;
        z-index: 1!important;
        box-shadow: 20px 20px rgb(32, 32, 32);

}
#produits p{
        margin: 1rem;
        text-align: center;
}
#produits ul{
        display: block;
        font-weight: normal;
        margin: 2rem;
        list-style: none;
     
}
#h24{
        width: 80%;
        margin: auto;
        background-color: rgb(14, 28, 41);
        color: white;
}
#h24 ul{
        margin: 0 4rem;
        font-weight: normal;
        display: block;
        list-style: none;
}
#h24 ul li{
        text-align: center;
}
#h24 p{
        margin: 2rem;
        text-align: center;
}
#h24 b{
        text-decoration: yellow underline;
}
#depan{
        display: flex;
        width: 80%;
        margin: auto;
}
#depan div{
        flex: 1;
}
#depan div img{
        width: 100%;
        height: 100%;
}