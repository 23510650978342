@media screen and (max-width:1200px){
        #arrow{display: none;}
        #espace{display: none;}
        #banniere{display: block;}
        #ban1{display: none;}
        #ban2 div{display: flex;}
        #ban2 h2{width: 60%; margin: auto; flex: 4;}
        #menu{display: none;}
        #padHeader{display: block;text-align: center; background-color: rgba(255, 255, 255, 0.9);padding: 1rem;}
        #padHeader img{width:200px;}
        #padLegal{position: static; left: 0.5rem; height: 40px; top: 0.7rem; display: block; padding: 1rem;}
        #padNavig a{font-weight: bold; color: rgb(14, 28, 41); text-decoration: none; font-size: 1.1rem; text-transform: uppercase;}
        #presentation{width: 50%;}
        #Metal{width: 50%;}
        #Metal h2{font-size: 2rem;}
        #Facades{width: 50%;}
        #Facades h2{font-size: 2rem;}
        #Batiments{width: 50%;}
        #Batiments h2{font-size: 2rem;}
        #References{width: 50%;}
        #References h2{font-size: 2rem;}
        #Contact{width: 50%;}
        #Legalo{width: 50%;}
        #Legalo h2{font-size: 2rem;}
        h1{font-size: 3rem !important;}
        #nousContacter h3{font-size: 2rem;}
        #nousContacter li{font-size: 1.2rem;}
        main h2{width: 90%; margin: 5rem auto;}
        header{height: 800px !important;}
        #exp{width: 90% !important;}
        #plusengage{width: 90% !important;}
        #fournisseurs{margin-left: 5% !important; width: 45% !important;}
        #produits{width: 45% !important;}
        #h24{width: 90% !important;}
        #depan{width: 90% !important;}
        #partenaires{width: 90% !important;}
        #inox{margin-left: 5% !important; width: 45% !important;}
        #acier{width: 45% !important;}
        #escaliers{width: 90% !important;}
        #mezza{width: 90% !important;}
        #travaillons{width: 90% !important;}
        #allu{margin-left: 5% !important; width: 45% !important;}
        #bois{width: 45% !important;}
        #windows{width: 90% !important;}
        #windows div {padding: 0rem !important;}
        #windows ul{margin-top: 0rem !important;}
        #windows li{margin-top: 0rem !important;}
        #baie{width: 90% !important;}
        #miroir{width: 90% !important;}
        #volets{width: 90% !important;}
        #rido{width: 90% !important;}
        #baie img{height: 100% !important;}
        #blinde{width: 90% !important;}
        #serrure{margin-left: 5% !important; width: 45% !important;}
        #modele{width: 45% !important;}
        #urgence{width: 90% !important;}
        #admin{width: 90% !important;}
        #commerces{width: 90% !important;}
        #commerces ul{margin: 0 !important; margin: 2rem !important;}
        #assurances1{margin-left: 5% !important; width: 45% !important;}
        #assurances2{width: 45% !important;}
        #formulaire{width: 90% !important;}
        #b form{margin: auto; box-sizing: border-box; width: 95%;}
        #b form input{box-sizing: border-box; margin: 0 auto;}
        #b form textarea{box-sizing: border-box; margin: 0 auto;}
}

@media screen and (max-width:750px){
        h1{font-size: 2rem !important;}
        header{height: 850px;}
        #ban2 h2{font-size: 0.5rem;}
        #padNavig a{font-size: 1rem;}
        #padHeader img{width:150px;}
        #presentation{width: 90%;box-sizing: border-box;}
        #presentation img{width: 100px;}
        #Metal{width: 90%; box-sizing: border-box;}
        #Metal h2{font-size: 1.5rem;}
        #Facades{width: 90%;box-sizing: border-box;}
        #Facades h2{font-size: 1.5rem;}
        #Batiments{width: 90%;box-sizing: border-box;}
        #Batiments h2{font-size: 1.5rem;}
        #References{width: 90%;box-sizing: border-box;}
        #References h2{font-size: 1.5rem;}
        #Contact{width: 90%;box-sizing: border-box;}
        #Contact img{width: 70px;}
        #Contact p{font-size: 1rem;}
        #Legalo{width: 90%;box-sizing: border-box;}
        #Legalo h2{font-size: 1.5rem;}
        main h2{font-size: 2rem;}
        footer{padding-top: 1rem; padding-left: 1rem;padding-right: 1rem; }
        footer ul{list-style: none;}
        #foot{display: block;}
        #exp{display: block !important;}
        #exp div{padding-top: 1rem !important;}
        #plusengage{margin-top: 3rem !important;}
        #fournisseurs{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important;}
        #produits{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important; margin-top: 6rem !important;}
        #partenaires{display: block !important;}
        #partenaires div{padding-top: 1rem !important;}
        #exp{display: block !important;}
        #exp{display: block !important;}
        #inox{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important; margin-top: 6rem !important;}
        #acier{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important; margin-top: 6rem !important;}
        #escaliers{display: block !important;}
        #mezza{display: block !important;}
        #mezza div{padding: 1rem !important;}
        #mezza div p{margin-top: 2rem !important;}
        #travaillons{display: block !important;}
        #travaillons div{padding: 1rem !important;}
        #allu{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important; margin-top: 6rem !important;}
        #bois{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important; margin-top: 6rem !important;}
        #windows{display: block !important;}
        #windows div{padding: 0.5rem !important; text-align: center !important;}
        #windows li{margin-top: -0.5rem !important;}
        #baie{display: block !important;}
        #baie div{padding: 1rem !important;}
        #miroir{display: block !important;}
        #miroir div{padding: 1rem !important;}
        #volets{display: block !important;}
        #volets div{padding: 1rem !important;}
        #blinde{display: block !important;}
        #blinde div{padding: 1rem !important;}
        #serrure{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important; margin-top: 6rem !important;}
        #modele{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important; margin-top: 6rem !important;}
        #urgence{display: block !important;}
        #urgence div{padding: 1rem !important;}
        #admin{display: block !important; height: auto !important;}
        #admin div{padding: 1rem !important;}
        #commerces{display: block !important;}
        #commerces div{padding: 1rem !important;}
        #assurances1{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important; margin-top: 6rem !important;}
        #assurances2{width: 90% !important; margin: auto !important; box-shadow: 10px 10px rgb(32, 32, 32) !important; margin-top: 6rem !important;}
        #nousContacter{display: block !important;}
        #formulaire{display: block !important;}
        #a{padding: 1rem;}
        #b{padding: 1rem;}
        #lois section{width: 90% !important;}
        #lois section ul{width: 60% !important;}
}

@media screen and (max-width:300px){
        h1{font-size: 1.5rem !important;}
}
@media screen and (min-width:1200px){
        #padNavig{display: none !important;}
}