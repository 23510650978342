#mention{
        background-color: rgb(14, 28, 41);
        color: white;
        padding: 1rem;
        text-align: center;
        font-size: 1.2rem;
}
#mention p{
        width: 80%;
        margin: 0.5rem auto;
}
#lois section{
        width: 80%;
        margin: auto;
        text-align: justify;
        font-size: 1rem;
}
#lois section p{
        margin: 2rem;
}
#lois h3{
        margin: 2rem;
}
#lois ul{
        font-weight: normal;
        margin: auto;
        width: 50%;
        text-align: left;
}
#lois ul li{
        text-align: justify;
        list-style: square;
        font-size: 0.9rem;
        margin: 0.2rem;
}