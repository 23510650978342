footer{
        margin: 0;
        margin-top: 6rem;
        background-color: rgb(236, 236, 236);
        color: rgb(14, 28, 41);
}
footer a{
        color: rgb(14, 28, 41);
        text-decoration: none;
        font-weight: bold;
}
#foot{
        display: flex;
}
#foot div{
        flex: 1;
        height: 100%;
}
#foot p{
        margin: 2rem;
        text-align: center;
        font-weight: bold;
        text-decoration: underline;
}
footer ul{
        margin: 0;
        display: block;
        font-weight: normal;
}
footer hr{
        margin-top: 3rem;
        border: 0.5px solid rgb(14, 28, 41);
        border-collapse: collapse;
}
.noFooter{
        text-decoration: none!important;
        font-weight: normal!important;
}
#ms{
        text-align: center;
        padding-bottom: 3rem;
      
}
#no{
        visibility: hidden;
}